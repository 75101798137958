import { Component, Input, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NullableDate, NullableString } from '@core/types/nullable'
import { CashDisbursementRead, CashDocumentsService, InvoicesService, IssuerRead } from '@app/generated'
import { Pagination } from '@core/classes/pagination'
import { Router } from '@angular/router'
import { LoaderService } from '@core/services/loader.service'
import { AppService } from '@app/general/services/app.service'
import { NotificationService } from '@core/services/notification.service'
import { ModalService } from '@core/services/modal.service'
import { config } from '@config'
import { finalize, forkJoin, of } from 'rxjs'
import { calculateGrossValueFromNetValue } from '@app/invoices/utils/calculate-gross-value-from-net-value'
import { calculateDivision } from '@app/invoices/utils/calculate-division'
import { formatDate } from '@app/general/utils/format-date'
import { SignaComponent } from '@app/general/classes/signa-component'
import { saveAs } from 'file-saver'
import { Permission } from '@app/acl/enums/permissions.enum'
import { getLanguageCode } from '@app/general/utils/get-language-code'
import { LanguageService } from '@core/services/language.service'
import { mod } from '@tensorflow/tfjs'
import { LayoutService } from '@core/services/layout.service'
import { setLocalStorageScroll } from '@app/cost-invoices/pages/cost-invoices/cost-invoices.page'
import { ScrollItem } from '@app/orders/components/order-list/order-list.component'

export const CashDisbursementsFiltersLocalStorageKey = 'cash-disbursement-view-list-filters'
export const CashDisbursementsListScrollStorageKey = 'cash-disbursement-view-list-scroll'

type SortBy = 'created_at' | 'updated_at' | 'deleted_at' | 'document_number'

export type CashDisbursementsFiltersValues = {
	[P in keyof FiltersFormScheme]: FiltersFormScheme[P]['value']
}
export type CashDisbursementsFiltersAndSearchValues = {
	filters: CashDisbursementsFiltersValues | undefined
	sortBy?: SortBy
	sortOrder?: 'asc' | 'desc'
	perPage?: number
	pageNumber?: number
}

interface FiltersFormScheme {
	search: FormControl<NullableString>
	dateFrom: FormControl<NullableDate>
	dateTo: FormControl<NullableDate>
	issuerID: FormControl<NullableString>
}

@Component({
	selector: 'app-cash-disbursement',
	templateUrl: './cash-disbursement.page.html',
	styleUrl: './cash-disbursement.page.scss',
})
export class CashDisbursementPage extends SignaComponent implements OnInit {
	@Input() transfer: boolean = false

	models: CashDisbursementRead[] = []
	pagination: Pagination = new Pagination()
	languageCode: string = 'en'
	filtersFormGroup: FormGroup<FiltersFormScheme> = new FormGroup({
		search: new FormControl<NullableString>(null, [Validators.maxLength(128)]),
		dateFrom: new FormControl<NullableDate>(null),
		dateTo: new FormControl<NullableDate>(null),
		issuerID: new FormControl<NullableString>(null),
	})
	issuers: IssuerRead[] = []
	isLoaded: boolean = false
	scrollY: number = 0

	protected readonly textRemoveFilters = $localize`Clear filters`
	protected readonly textSearch = $localize`Search`
	protected readonly textDateOfIssue = $localize`Date of issue`
	protected readonly textFrom = $localize`From`
	protected readonly textTo = $localize`To`
	protected readonly textShowAll = $localize`Show all`
	protected readonly textDelete = $localize`Delete`
	protected readonly textIssuer = $localize`Issuer`
	protected readonly textInvoiceType = $localize`Invoice type`
	protected readonly textDeleteCashDisbursement = $localize`Delete cash disbursement`
	protected readonly textDeleteTransfer = $localize`Delete outgoing transfer`
	protected readonly textDownloadPdf = $localize`Download PDF`
	protected readonly textEdit = $localize`Edit`
	private selectedModels: CashDisbursementRead[] = []

	constructor(
		private router: Router,
		private loaderService: LoaderService,
		private appService: AppService,
		private cashDocumentsService: CashDocumentsService,
		private notificationService: NotificationService,
		private invoicesService: InvoicesService,
		private modalService: ModalService,
		private languageService: LanguageService,
		private layoutService: LayoutService,
	) {
		super()
		this.loaderService.setCover().enable()
		this.languageCode = getLanguageCode(this.languageService.getSelectedLanguage ?? 'en-US')
	}

	get getSelectedIssuer(): IssuerRead | undefined {
		return this.issuers.find(i => i.id === this.filtersFormGroup.controls.issuerID.value)
	}

	ngOnInit(): void {
		if (this.transfer) {
			this.appService.title = $localize`List of outgoing transfers`
		} else {
			this.appService.title = $localize`List of cash disbursements`
		}

		const scroll = this.getLocalStorageScroll

		if (scroll?.scrollY) {
			const interval = setInterval(() => {
				if (this.isLoaded) {
					this.layoutService.layoutContainer?.nativeElement.scrollTo({
						top: scroll.scrollY ?? 0,
					})
					clearInterval(interval)
				}
			}, 300)
		}

		this.pagination.currentPageChanges().subscribe(() => {
			if (this.isLoaded) {
				sessionStorage.removeItem(CashDisbursementsListScrollStorageKey)
			}
		})

		this.layoutService.afterScroll.subscribe(event => {
			const target = event!.target as HTMLElement

			if (target) {
				this.scrollY = target.scrollTop
			}
		})

		const filters = this.getLocalStorageFilterAndSort
		if (filters?.filters) {
			this.filtersFormGroup.controls.search.setValue('')
			this.filtersFormGroup.patchValue(filters.filters)
			this.pagination.setPerPage(filters.perPage ?? 25)
		}

		this.pagination.listShouldBeReloaded().subscribe(() => {
			this.fetchData()
		})
	}

	create(): void {
		if (this.transfer) {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`/${config.modules.cashDocuments.path}/transfer-outgoing/create`)
			})
		} else {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`/${config.modules.cashDocuments.path}/cash-disbursement/create`)
			})
		}
	}

	downloadPdf(model: CashDisbursementRead, language: string): void {
		console.log(model, language)
		this.loaderService.setContain().enable(async () => {
			this.cashDocumentsService
				.getCashDisbursementPdf(model.id, language as any, undefined, true, {
					httpHeaderAccept: 'application/pdf',
				})
				.pipe(finalize(() => this.loaderService.disable()))
				.subscribe({
					next: (res: Blob) => {
						let name = $localize`Cash disbursement` + '-' + model.customer_name + '-' + model.issue_date + '.pdf'
						name = name.replace(/\W+/g, '-')
						saveAs(res, name.toLowerCase())
					},
					error: err => {
						console.log(err)
						this.notificationService.error($localize`Something went wrong.`)
					},
				})
		})
	}

	getGrossValue(model: CashDisbursementRead): number {
		return calculateGrossValueFromNetValue({
			netValue: model.net_value,
			taxRate: calculateDivision(model.tax_rate.value, 100),
		})
	}

	confirmFilters(): void {
		this.pagination.reloadList()
	}

	async delete(model: CashDisbursementRead): Promise<void> {
		const modal = await this.modalService.confirmation({
			title: $localize`Delete cash receipt`,
			message: $localize`Are you sure you want to delete?`,
		})

		modal.subscribe({
			next: value => {
				if (value) {
					this.loaderService.setContain().enable(() => {
						this.cashDocumentsService.deleteCashDisbursement(model.id).subscribe({
							next: () => this.fetchData(),
							error: err => {
								console.log(err)
								this.loaderService.disable()
								this.notificationService.error($localize`Something went wrong.`)
							},
						})
					})
				}
			},
		})
	}

	goToUpdate(model: CashDisbursementRead) {
		if (this.transfer) {
			setLocalStorageScroll(model.id, this.scrollY, CashDisbursementsListScrollStorageKey)
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`/cash-documents/transfer-outgoing/${model.id}/update`)
			})
		} else {
			setLocalStorageScroll(model.id, this.scrollY, CashDisbursementsListScrollStorageKey)
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(`/cash-documents/cash-disbursement/${model.id}/update`)
			})
		}
	}

	checkboxesChanged(models: CashDisbursementRead[]) {
		this.selectedModels = models
	}

	aclCanCashDisbursementRead(): boolean {
		return this.aclService.hasPermission([Permission.CASH_DISBURSEMENTS_READ])
	}

	aclCanCashDisbursementCreate(): boolean {
		return this.aclService.hasPermission([Permission.CASH_DISBURSEMENTS_CREATE])
	}

	aclCanCashDisbursementUpdate(): boolean {
		return this.aclService.hasPermission([Permission.CASH_DISBURSEMENTS_UPDATE])
	}

	aclCanCashDisbursementDelete(): boolean {
		return this.aclService.hasPermission([Permission.CASH_DISBURSEMENTS_DELETE])
	}

	async deleteSelected(): Promise<void> {
		if (this.selectedModels.length === 0) {
			this.notificationService.error($localize`You have to select the documents you want to delete.`)
		} else {
			;(
				await this.modalService.confirmation({
					title: $localize`Are you sure you want to delete?`,
					message: $localize`Are you sure you want to delete all selected trash documents? This action cannot be undone.`,
				})
			).subscribe({
				next: value => {
					if (value) {
						this.loaderService.setContain().enable(() => {
							forkJoin(
								this.selectedModels.map(model => this.cashDocumentsService.deleteCashDisbursement(model.id)),
							).subscribe({
								next: () => {
									this.pagination.reloadList()
								},
								error: err => {
									console.error(err)
									this.loaderService.disable()
									this.notificationService.error($localize`An error occurred while deleting the cash disbursement.`)
								},
							})
						})
					}
				},
			})
		}
	}

	private fetchIssuers() {
		if (this.issuers.length) {
			return of(this.issuers)
		} else {
			return this.invoicesService.getIssuers()
		}
	}

	private fetchData(): void {
		const filters = this.filtersFormGroup.value
		this.setCashDisbursementsLocalStorageFiltersAndSort()
		let issueDateTo: string | undefined
		let issueDateFrom: string | undefined
		if (filters.dateFrom instanceof Date) {
			issueDateFrom = formatDate(filters.dateFrom)
		} else if (filters.dateFrom) {
			issueDateFrom = formatDate(new Date(filters.dateFrom))
		}
		if (filters.dateTo instanceof Date) {
			issueDateTo = formatDate(filters.dateTo)
		} else if (filters.dateTo) {
			issueDateTo = formatDate(new Date(filters.dateTo))
		}
		this.loaderService.setContain().enable(async () => {
			forkJoin({
				issuers: this.fetchIssuers(),
				cashReceipts: this.cashDocumentsService.getCashDisbursements(
					this.pagination.offset(),
					this.pagination.perPage,
					this.filtersFormGroup.controls.search.value ?? undefined,
					undefined,
					undefined,
					undefined,
					this.filtersFormGroup.controls.issuerID.value ?? undefined,
					filters.dateFrom ? issueDateFrom : undefined,
					filters.dateTo ? issueDateTo : undefined,
					undefined,
					undefined,
					this.transfer ?? undefined,
				),
			})
				.pipe(finalize(() => this.loaderService.disable()))
				.subscribe({
					next: async response => {
						this.pagination.setTotal(response.cashReceipts.total)
						this.models = response.cashReceipts.items
						if (!this.isLoaded) {
							const filters = this.getLocalStorageFilterAndSort
							if (filters) {
								this.pagination.setCurrentPage(filters.pageNumber ?? 1)
							}
							this.isLoaded = true
						}
						this.setCashDisbursementsLocalStoragePageNumber()
						this.issuers = response.issuers
					},
					error: err => {
						console.error(err)
						this.notificationService.error($localize`Something went wrong.`)
					},
				})
		})
	}

	setCashDisbursementsLocalStorageFiltersAndSort(): void {
		const filters = this.getLocalStorageFilterAndSort
		sessionStorage.setItem(
			CashDisbursementsFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filtersFormGroup.value ?? null,
				perPage: this.pagination.perPage,
				pageNumber: filters?.pageNumber ?? 1,
			}),
		)
	}

	setCashDisbursementsLocalStoragePageNumber(): void {
		sessionStorage.setItem(
			CashDisbursementsFiltersLocalStorageKey,
			JSON.stringify({
				filters: this.filtersFormGroup.value ?? null,
				perPage: this.pagination.perPage,
				pageNumber: this.pagination.currentPage,
			}),
		)
	}

	get getLocalStorageFilterAndSort(): CashDisbursementsFiltersAndSearchValues | undefined {
		const storedFilters = sessionStorage.getItem(CashDisbursementsFiltersLocalStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}

	protected readonly mod = mod

	get getLocalStorageScroll(): ScrollItem | undefined {
		const storedFilters = sessionStorage.getItem(CashDisbursementsListScrollStorageKey)

		if (!storedFilters) {
			return undefined
		}

		return JSON.parse(storedFilters)
	}

	clearFilters() {
		sessionStorage.removeItem(CashDisbursementsListScrollStorageKey)
	}
}
