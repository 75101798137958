import { Component } from '@angular/core'
import { InvoiceRead, InvoiceReadList, InvoicesService } from '@app/generated'
import { FormControl, Validators } from '@angular/forms'
import { NullableBoolean, NullableString } from '@core/types/nullable'
import { NotificationService } from '@core/services/notification.service'
import { HttpErrorResponse } from '@angular/common/http'
import { Logger } from '@app/general/utils/logger.util'
import { Subscription } from 'rxjs'
import { ModalService } from '@core/services/modal.service'

@Component({
	selector: 'app-assign-cash-receipts',
	templateUrl: './assign-cash-receipts.component.html',
	styleUrl: './assign-cash-receipts.component.scss',
})
export class AssignCashReceiptsComponent {
	invoices: InvoiceRead[] = []
	searchValue: string = ''
	invoiceID: FormControl<NullableString> = new FormControl<NullableString>(null, [Validators.required])
	showPaidControl: FormControl<NullableBoolean> = new FormControl(false)

	private readonly logger: Logger = new Logger()
	private subscription?: Subscription

	constructor(
		private modalService: ModalService,
		private invoicesService: InvoicesService,
		private notificationService: NotificationService,
	) {}

	fetchInvoices(): void {
		if (this.subscription) {
			this.subscription.unsubscribe()
		}
		setTimeout(() => {
			this.subscription = this.invoicesService
				.getInvoices(
					0,
					10,
					this.searchValue,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					undefined,
					false,
					undefined,
					undefined,
					undefined,
				)
				.subscribe({
					next: (response: InvoiceReadList) => {
						this.invoices = response.items
					},
					error: (error: HttpErrorResponse) => {
						this.logger.error('Something went wrong.', error)
						this.notificationService.error($localize`Something went wrong.`)
					},
				})
		})
	}

	submit(): void {
		if (this.invoiceID.valid) {
			this.modalService.modalRef.close(this.invoiceID.value)
		} else {
			this.notificationService.error($localize`Please choose an invoice.`)
		}
	}
}
