<epic-card
	[collapseState]="false"
	class="margin__bottom--large">
	<div
		i18n
		start>
		Filters
	</div>
	<div
		class="row"
		end>
		<epic-icon-button
			(click)="filtersFormGroup.reset(); confirmFilters(); $event.stopPropagation(); pagination.reset(); clearFilters()"
			[text]="textRemoveFilters"
			hoverColor="var(--color__danger)"
			iconHoverColor="var(--color__white)"
			iconHoverQuerySelector="path"
			iconName="broom" />
	</div>
	<form
		#formElement="ngForm"
		(ngSubmit)="confirmFilters()"
		class="form">
		<epic-form-field
			[text]="textSearch"
			class="form__element-3">
			<epic-input
				[focused]="!!filtersFormGroup.controls.search.value"
				[control]="filtersFormGroup.controls.search"
				(keyup.enter)="formElement.onSubmit($event)"
				[placeholder]="textSearch" />
		</epic-form-field>
		<epic-form-field
			[text]="textIssuer"
			class="form__element-3">
			<epic-select
				[focused]="!!filtersFormGroup.controls.issuerID.value"
				[control]="filtersFormGroup.controls.issuerID"
				[customSelectedValues]="true">
				<div
					class="epic-select__custom-values"
					selected-values>
					<span class="epic-select__value-round">{{ getSelectedIssuer?.name }}</span>
				</div>
				<epic-option
					[text]="textShowAll"
					[value]="null" />
				@for (iss of issuers; track iss) {
				<epic-option
					[text]="iss.name"
					[value]="iss.id" />
				}
			</epic-select>
		</epic-form-field>
		<epic-form-field-group
			[text]="textDateOfIssue"
			class="form__element-3"
			datePicker>
			<epic-input
				[focused]="!!filtersFormGroup.controls.dateFrom.value"
				[control]="filtersFormGroup.controls.dateFrom"
				[placeholder]="textFrom" />
			<epic-input
				[focused]="!!filtersFormGroup.controls.dateTo.value"
				[control]="filtersFormGroup.controls.dateTo"
				[placeholder]="textTo" />
		</epic-form-field-group>
		<div class="form__element-12 row justify__end">
			<epic-button
				(onClick)="formElement.onSubmit($event)"
				size="large">
				<epic-icon name="document-2-black" />
				<span i18n>Show documents</span>
			</epic-button>
		</div>
	</form>
</epic-card>
<epic-card [isCollapseAllowed]="false">
	<div
		class="row gap--large"
		start>
		@if (transfer) {
		<span i18n>List of outgoing transfers</span>
		} @else {
		<span i18n>List of cash disbursements</span>
		} @if (aclCanCashDisbursementCreate()) {
		<epic-button
			classes="epic-button--square"
			(onClick)="create()">
			<epic-icon name="plus-mini" />
		</epic-button>
		}
	</div>
	@if (aclCanCashDisbursementDelete()) {
	<div end>
		<epic-icon-button
			(click)="deleteSelected()"
			iconHoverQuerySelector="path"
			[text]="textDelete"
			[background]="'var(--color__white)'"
			[hoverColor]="'var(--color__danger)'"
			[tooltipTextColor]="'var(--color__white)'"
			[iconName]="'delete'"
			[iconHoverColor]="'var(--color__white)'" />
	</div>
	}
	<epic-pagination [pagination]="pagination" />
	<epic-table
		(checkboxesChange)="checkboxesChanged($event)"
		class="order-finances__table font__small margin__bottom--medium"
		[generateCheckboxes]="true">
		<tr
			table-headers
			table-row-data
			[is-header-checkbox]="true">
			<epic-table-header class="font-align__center table-column__xs">
				<span i18n="@@ordinalNumber">No.</span>
			</epic-table-header>
			<epic-table-header class="table-column__md">
				<span i18n>Document number</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Contractor</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Place</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Date</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Net value</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>VAT rate</span>
			</epic-table-header>
			<epic-table-header>
				<span i18n>Gross value</span>
			</epic-table-header>
			<epic-table-header />
		</tr>
		@for (model of models; track model.id) {
		<tr
			[class]="{'focus--border': getLocalStorageScroll?.scrollTargetId === model.id}"
			[table-row-data]="model">
			<td class="table-column__xs font-align__center">
				{{ $index + 1 + (pagination.currentPage! > 1 ? pagination.currentPage! - 1 : 0) * (pagination.perPage || 0) }}
			</td>
			<td class="table-column__md">
				<span class="font-weight__medium"> {{ model.document_number }} </span>
			</td>
			<td class="">{{ model.customer_name }}</td>
			<td class="table-column__sm">{{ model.place }}</td>
			<td class="table-column__sm">{{ model.issue_date | date : 'dd.MM.YYYY' }}</td>
			<td class="table-column__sm">
				{{ model.net_value | currency : model.currency : 'symbol' : '0.2-2' : languageCode }}
			</td>
			<td class="table-column__sm">{{ getItemTranslation(model.tax_rate) }}</td>
			<td class="table-column__sm">
				{{ getGrossValue(model) | currency : model.currency : 'symbol' : '0.2-2' : languageCode }}
			</td>
			<td class="table-column__xs">
				<div class="cash-disbursement__buttons">
					<div #downloadPdfElement>
						@if (!model.is_transfer) {
						<epic-icon-button
							[text]="textDownloadPdf"
							[hoverColor]="'var(--color__dark)'"
							[iconHoverColor]="'var(--color__white)'"
							[tooltipTextColor]="'var(--color__white)'"
							[iconHoverQuerySelector]="'path'"
							[iconName]="'file-pdf'" />
						}
					</div>
					<epic-icon-button
						(click)="goToUpdate(model)"
						[text]="textEdit"
						[hoverColor]="'var(--color__dark)'"
						[iconHoverColor]="'var(--color__white)'"
						[tooltipTextColor]="'var(--color__white)'"
						[iconHoverQuerySelector]="'path'"
						[iconName]="'edit'" />
					<epic-icon-button
						(click)="delete(model)"
						[text]="model.is_transfer? textDeleteTransfer: textDeleteCashDisbursement"
						hoverColor="var(--color__danger)"
						iconHoverColor="var(--color__white)"
						iconHoverQuerySelector="path"
						iconName="delete" />
				</div>
			</td>
			<epic-popover
				#downloadPdfPopoverComponent
				[parentReference]="downloadPdfElement">
				<div
					popover-header
					i18n>
					Select the invoice language
				</div>
				<div
					class="epic-popover__list"
					popover-content>
					@for (languageCode of appConfig.invoiceLanguages; track languageCode) {
					<epic-language-switcher-element
						[languageCode]="languageCode"
						(click)="downloadPdf(model, languageCode);downloadPdfPopoverComponent.closePopover();$event.stopPropagation()" />
					}
				</div>
			</epic-popover>
		</tr>
		}
	</epic-table>
	<epic-pagination [pagination]="pagination" />
</epic-card>
