<form
	class="form"
	#formElement="ngForm">
	<epic-form-field class="form__element-12">
		<epic-autocomplete
			[(searchValue)]="searchValue"
			(searchValueChange)="fetchInvoices()"
			(onFocusInSearchValue)="fetchInvoices()"
			[control]="invoiceID">
			@for (invoice of invoices; track $index) {
				<epic-option
					[text]="invoice.document_number ?? ''"
					[value]="invoice.id" />
			}
		</epic-autocomplete>
	</epic-form-field>
	<div class="form__element-12">
		<div class="row-reverse">
			<epic-button
				type="button"
				(onClick)="submit()"
				[isConfirm]="true">
				<span i18n>Confirm</span>
			</epic-button>
		</div>
	</div>
</form>
